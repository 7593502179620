<template>
  <div>
    <h4 class="font-weight-bold pb-1 primary--text">Gestión</h4>
    <h5 class="font-weight-light">Gestión de Presupuestos</h5>
    <v-form ref="formulario" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12">
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Antecedentes Presupuesto
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!--<v-col cols="12" lg="6">
                        <v-select
                          :items="tipo"
                          v-model="datosFormulario.tipo"
                          item-value="tipo"
                          item-text="text"
                          label="Tipo"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>-->
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nombre Presupuesto"
                          v-model="datosFormulario.nombreProyecto"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nº Presupuesto"
                          v-model="datosFormulario.nro_presupuesto"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                          :append-icon="'mdi-download'"
                          @click:append="getLastNumber()"
                          v-if="!idDato"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Nº Presupuesto"
                          v-model="datosFormulario.nro_presupuesto"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                          v-else
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="mandantes"
                          v-model="datosFormulario.mandante"
                          item-value="_id"
                          item-text="nombre"
                          label="Mandante"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                          v-on:change="changeSelectMandantes"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="
                            mandantes.filter((c) => c._id === datosFormulario.mandante)[0]
                              .contactos
                          "
                          v-model="datosFormulario.contacto_mandante"
                          item-value="_id"
                          item-text="nombre"
                          label="Contacto Mandante"
                          outlined
                          hide-details
                          v-if="datosFormulario.mandante"
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                        <v-col cols="12" lg="6" v-if="!datosFormulario.obras && datosFormulario.mandante">
                          <v-switch v-model="datosFormulario.obras" label="Asignar a Obra" color="primary" hide-details class="ml-5" />
                        </v-col>
                        <v-col cols="12" lg="6" v-if="datosFormulario.obras">
                        <v-select
                          :items="mandantesObras"
                          v-model="datosFormulario.obra"
                          item-value="_id"
                          item-text="nombre"
                          label="Obra"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                          v-on:change="changeSelectMandantesObra"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6" v-if="datosFormulario.obras  && idDato">
                        <v-select
                          :items="
                            mandantesObras.filter((c) => c._id === datosFormulario.obra._id)[0]
                              .contactos
                          "
                          v-model="datosFormulario.contacto_obra"
                          item-value="_id"
                          item-text="nombre"
                          label="Contacto Obra"
                          outlined
                          hide-details
                          v-if="datosFormulario.obra"
                          :rules="basic_validation_rules_select"
                          required
                          v-on:change="changeSelectContactoObra"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" lg="6" v-else-if="datosFormulario.obras">
                        <v-select
                          :items="
                            mandantesObras.filter((c) => c._id === datosFormulario.obra)[0]
                              .contactos
                          "
                          v-model="datosFormulario.contacto_obra"
                          item-value="_id"
                          item-text="nombre"
                          label="Contacto Obra"
                          outlined
                          hide-details
                          v-if="datosFormulario.obra"
                          :rules="basic_validation_rules_select"
                          required
                          v-on:change="changeSelectContactoObra"
                        ></v-select>
                      </v-col>
                        <v-col cols="12" lg="6" v-if="!datosFormulario.obras && datosFormulario.mandante">
                          
                        </v-col>


                      
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="tipos_moneda"
                          v-model="datosFormulario.tipo_moneda"
                          item-value="tipo"
                          item-text="text"
                          label="Tipo Moneda"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        ></v-select>
                      </v-col>
                     <!-- <v-col cols="12" lg="6">
                        <v-text-field
                          label="Valor Proyectos"
                          v-model="datosFormulario.valorProyectos"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Valor ITO"
                          v-model="datosFormulario.valorIto"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>-->
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Valor"
                          v-model="datosFormulario.valor"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Fecha de Envío"
                              v-model="datosFormulario.fecha_envio"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                              :rules="basic_validation_rules_input"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datosFormulario.fecha_envio"
                            locale="es-es"
                            color="verde primary--text"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="estados"
                          v-model="datosFormulario.status"
                          item-value="tipo"
                          item-text="text"
                          label="Estado"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="6" v-if="datosFormulario.status == '2'">
                        <v-file-input
                          v-model="archivotemp_presupuesto"
                          hide-details
                          label="Documento"
                          type="file"
                          ref="file_presupuesto"
                          @change="selectFile_presupuesto"
                          prepend-icon="mdi-paperclip"
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-textarea
                          label="Observaciones"
                          v-model="datosFormulario.observacion"
                          outlined
                          rows="3"
                          hide-details
                        >
                        </v-textarea>
                      </v-col>

                      <v-col cols="12" lg="6" v-if="datosFormulario.status == '2'">
                        <v-menu
                          v-model="menu4"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Fecha de Adjudicación"
                              v-model="datosFormulario.fecha_adjudicacion"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                              style="background: #ffffff"
                              :rules="basic_validation_rules_input"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datosFormulario.fecha_adjudicacion"
                            locale="es-es"
                            color="verde primary--text"
                            @input="menu4 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                class="mb-2 verde primary--text"
                v-if="idDato"
                @click="editData"
                right
                >Editar</v-btn
              >
              <v-btn
                :loading="loading"
                class="mb-2 verde primary--text"
                v-if="!idDato"
                @click="saveData"
                right
                >Grabar</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-card class="elevation-3 mt-5" v-if="status_bd == '2'">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Proyectos
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-card>
                      <v-menu top :offset-y="offset">
                        <template v-slot:activator="{ on, attrs }">
                          <v-fab-transition>
                            <v-btn
                              color="primary verde--text"
                              dark
                              absolute
                              top
                              right
                              fab
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-fab-transition>
                        </template>

                        <v-list>
                          <v-list-item>
                            <v-list-item-title
                              @click="
                                $router.push({
                                  name: 'proyecto',
                                  params: {
                                    presupuesto: idDato,
                                  },
                                })
                              "
                              style="cursor: pointer"
                              >Nuevo proyecto</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title
                              style="cursor: pointer"
                              @click="buscarProyectos()"
                              >Proyecto existente</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-tabs
                        v-model="tab"
                        background-color="primaryaccent"
                        color="primary"
                      >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="item in datosFormulario.proyectos" :key="item.tab">
                          {{ item.nro_proyecto }}
                        </v-tab>
                        <div
                          v-if="datosFormulario.proyectos.length < 1"
                          class="m-2 mx-auto"
                          align="center"
                        >
                          <b>Sin proyectos asignados</b>
                        </div>
                      </v-tabs>

                      <v-tabs-items v-model="tab">
                        <v-tab-item
                          v-for="item in datosFormulario.proyectos"
                          :key="item.tab"
                        >
                          <v-card flat>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Nº proyecto:
                                  </span>
                                  {{ item.nro_proyecto }}
                                </v-col>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Nombre del proyecto:
                                  </span>
                                  {{ item.nombre }}
                                </v-col>
                                <v-col cols="12" lg="4">
                                  <span class="primary--text font-weight-bold">
                                    Monto proyecto:
                                  </span>
                                  {{
                                    item.monto_documento
                                      | filtro_valor(datosFormulario.tipo_moneda)
                                  }}
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :loading="loading"
                                class="mb-2 verde primary--text"
                                v-if="idDato"
                                @click="
                                  $router.push({
                                    name: 'proyecto',
                                    params: { id: item._id },
                                  })
                                "
                                right
                                >Ver detalle del proyecto</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-3 mt-5" v-if="status_bd == '2'">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Documentos
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mb-2 verde primary--text"
                    @click="dialogNuevoDocumento = true"
                    >Nuevo Documento</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers_documentos"
                      :items="datosFormulario.archivos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:item.accion="{ item }">
                        
                        <v-chip
                          class="mr-2 p-1"
                          color="primary"
                          text-color="red"
                          small
                        >
                          <v-icon
                          small
                          v-if="usuarioDB.data.role == 'ADMIN'"
                          @click="deleteDocumento(item._id)"
                        >
                          mdi-delete
                          </v-icon>
                        </v-chip>
                        
                      </template>
                      <template v-slot:item.fecha_creacion="{ item }">
                        {{ item.fecha_creacion | fecha_normal }}
                      </template>
                      <template v-slot:item.archivo="{ item }">
                        <v-btn icon color="green">
                          <v-icon @click="abrirArchivo(item.archivo)"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogProyectoExistente"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Buscar Proyecto </v-card-title>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="ProyectoAsignar"
              :items="proyectos"
              item-text="nombre"
              item-value="id"
              dense
              outlined
              :loading="isLoadingResultProjects"
              label="Proyectos"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogProyectoExistente = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="asignarProyecto()"
              class="mb-2 indigo--text text--lighten-5 grey"
              v-if="ProyectoAsignar == ''"
              disabled
              >Asignar Proyecto</v-btn
            >
            <v-btn
              text
              @click="asignarProyecto()"
              class="mb-2 indigo--text text--lighten-5 primary"
              v-else
              >Asignar Proyecto</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogNuevoDocumento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Nuevo documento </v-card-title>
          <v-card-text class="mt-4">
            <v-text-field
              label="Nombre del documento"
              v-model="nombreArchivo"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-file-input
              v-model="archivotemp"
              hide-details
              label="Documento"
              type="file"
              ref="file"
              @change="selectFile"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogNuevoDocumento = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 grey"
              disabled
              v-if="files == null"
              >Subir documento</v-btn
            >
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="subirDocumento()"
              v-else
              >Subir documento</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="dialogObras"
    >
      <template v-slot:default="dialog">
        <FormularioObrasModal :padre="datosFormulario.mandante" :hijo="datosFormulario.obra" @cerrarobras="cerrarObras" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import FormularioObras from "../mandante/Formulario.obras.vue";
import FormularioObrasModal from "../mandante/Formulario.obras.modal.vue";
class datosReporte {
  constructor(datos = {}) {
    this.tipo = "1";
    this.nombreProyecto = datos.nombreProyecto;
    this.mandante = datos.mandante;
    this.contacto_mandante = datos.contacto_mandante;
    this.nro_presupuesto = datos.nro_presupuesto;
    this.status = datos.status;
    this.fecha_envio = datos.fecha_envio;
    this.fecha_adjudicacion = datos.fecha_adjudicacion;
    this.valorProyectos = 0;
    this.valorIto = 0;
    this.valor = datos.valor;
    this.tipo_moneda = datos.tipo_moneda;
    this.observacion = datos.observacion;
    this.proyectos = datos.proyectos;
    this.archivos = datos.archivos;
    this.archivo_presupuesto = datos.archivo_presupuesto;
    this.obras = datos.obras;
    this.obra = datos.obra;
    this.contacto_obra = datos.contacto_obra;
  }
}

export default {
    data() {
        return {
            hijo: null,
            archivotemp: null,
            archivotemp_presupuesto: null,
            files_presupuesto: null,
            dialogProyectoExistente: false,
            dialogObras: false,
            isLoadingResultProjects: false,
            ProyectoAsignar: "",
            valid: true,
            basic_validation_rules_input: [
                (v) => !!v || "Campo obligatorio",
                (v) => (v && v.length <= 50) || "Campo requerido",
            ],
            basic_validation_rules_email: [
                (v) => !!v || "E-mail es obligatorio",
                (v) => /.+@.+\..+/.test(v) || "Debe ingresar un Email válido",
            ],
            basic_validation_rules_select: [(v) => !!v || "Campo obligatorio"],
            loading: false,
            idDato: null,
            ex11: true,
            tipo: [
            /*{
              tipo: "1",
              text: "Proyectos",
            },
            {
              tipo: "2",
              text: "ITO",
            },*/
            ],
            tipos_moneda: [
                {
                    tipo: "1",
                    text: "U.F",
                },
                {
                    tipo: "2",
                    text: "Pesos Chilenos",
                },
            ],
            estados: [
                {
                    tipo: "1",
                    text: "Pendiente",
                },
                {
                    tipo: "2",
                    text: "Aprobado",
                },
                {
                    tipo: "3",
                    text: "Rechazado",
                },
            ],
            datosFormulario: new datosReporte(new datosReporte()),
            seleccionados: [],
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            menu3: false,
            menu4: false,
            noresult: "No existen resultados",
            search: "",
            sortBy: "ceco",
            sortDesc: false,
            headers: [
                {
                    text: "Nombre Contacto",
                    align: "start",
                    value: "nombre",
                },
                {
                    text: "Email",
                    align: "start",
                    value: "email",
                },
                {
                    text: "Teléfono",
                    align: "start",
                    value: "telefono",
                },
                {
                    text: "Estado",
                    align: "start",
                    value: "activo",
                },
                {
                    text: "Accion",
                    value: "accion",
                    sortable: false,
                },
            ],
            editedIndex: -1,
            editedItem: {
                nombre: "",
                email: "",
                telefono: "",
                activo: true,
            },
            dialog: false,
            dialogDelete: false,
            mandantes: [],
            mandantesObras: [],
            tab: null,
            items: [
                { tab: "PR-1234", content: "Tab 1 Content" },
                { tab: "PR-5342", content: "Tab 2 Content" },
            ],
            items2: [],
            opcionesProyecto: [
                {
                    tipo: 1,
                    texto: "Nuevo Proyecto",
                },
                {
                    tipo: 2,
                    texto: "Proyecto existente",
                },
            ],
            offset: true,
            status_bd: "0",
            proyectos: [],
            files: null,
            nombreArchivo: "",
            dialogNuevoDocumento: false,
            headers_documentos: [
                {
                    text: "Nombre Documento",
                    align: "start",
                    value: "nombre",
                },
                {
                    text: "Subido por",
                    align: "start",
                    value: "responsable.nombre",
                },
                {
                    text: "Fecha carga",
                    align: "start",
                    value: "fecha_creacion",
                },
                {
                    text: "Documento",
                    align: "start",
                    value: "archivo",
                },
                {
                    text: "Accion",
                    value: "accion",
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        
        abrirArchivo(archivo) {
            //window.open(process.env.VUE_APP_BASE_URL + "/files/presupuestos/" + archivo, "_blank");
            window.open(process.env.VUE_APP_BASE_URL + "/presupuestos/bajarDocumentoEstandar/" + archivo, "_blank");
        },
        selectFile(file) {
            this.files = file;
        },
        selectFile_presupuesto(file) {
            this.files_presupuesto = file;
        },
        buscarProyectos() {
            this.dialogProyectoExistente = true;
            this.isLoadingResultProjects = true;
            let resProjects = [];
            let config = {
                headers: {
                    token: this.token,
                },
            };
            let filtro = this.$options.filters;
            axios.get("/proyectos/proyecto/", config).then((response) => {
                for (let resp of response.data) {
                    //if(resp._id == )
                    var index = this.datosFormulario.proyectos.findIndex((x) => x._id == resp._id);
                    //console.log(index);
                    if (index === -1) {
                        resProjects.push({
                            id: resp._id,
                            nombre: `${resp.nro_proyecto} - ${resp.nombre}`,
                        });
                    }
                }
                this.proyectos = resProjects;
                this.isLoadingResultProjects = false;
            });
        },
        async asignarProyecto() {
            let config = {
                headers: {
                    token: this.token,
                },
            };
            await axios
                .put("/presupuestos/asignarProyecto/" + this.idDato, { idProyecto: this.ProyectoAsignar }, config)
                .then(async (response2) => {
                await axios
                    .put("/proyectos/asignarPresupuesto/" + this.ProyectoAsignar, { idPresupuesto: this.idDato }, config)
                    .then((response3) => {
                    this.$router.push({
                        name: "presupuesto",
                        params: {
                            id: this.idDato,
                        },
                    });
                    this.idDato = this.idDato;
                    this.getData();
                    this.dialogProyectoExistente = false;
                    this.$toast.success("Grabado con exito!", "", this.notificationSystem.options.error);
                })
                    .catch((e) => {
                    this.loading = false;
                    this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
                });
            })
                .catch((e) => {
                this.loading = false;
                this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
            });
        },
        async subirDocumento() {
            this.loading = true;
            let config = {
                headers: {
                    token: this.token,
                    "Content-Type": "multipart/form-data;",
                },
            };
            const formData = new FormData();
            formData.append("file", this.files);
            formData.append("nombre", this.nombreArchivo);
            formData.append("idPresupuesto", this.idDato);
            await axios
                .put("/presupuestos/subirDocumento/", formData, config)
                .then(async (response) => {
                this.loading = false;
                this.files = null;
                this.nombreArchivo = "";
                this.archivotemp = null;
                this.dialogNuevoDocumento = false;
                this.getData();
                this.$toast.success("Grabado con exito!", "", this.notificationSystem.options.error);
            })
                .catch((e) => {
                this.loading = false;
                this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
            });
        },
        async deleteDocumento(item) {
            //this.loading = true;
            let config = {
                headers: {
                    token: this.token,
                },
            };
            await axios
                .delete("/presupuestos/eliminarDocumento/" + item + "/" + this.idDato, config)
                .then(async (response) => {
                this.getData();
                this.$toast.success("Eliminado con exito!", "", this.notificationSystem.options.error);
            })
                .catch((e) => {
                this.loading = false;
                this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
            });
        },
        //[Fin guardamos datos en axios]
        validate() {
            this.$refs.formulario.validate();
        },
        validaRut: function (rutCompleto) {
            //rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            if (digv == "K")
                digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0, S = 1;
            for (; T; T = Math.floor(T / 10))
                S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        lg(tipo) {
            if (tipo == 1) {
                return this.usuarioDB.data.role == "ADMIN" ? 6 : 12;
            }
            else {
                return this.usuarioDB.data.role == "ADMIN" ? 12 : 6;
            }
        },
        async getMandantes() {
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        token: this.token,
                    },
                };
                axios.get("/mandantes/mandantes/1", config).then((response) => {
                    this.mandantes = response.data;
                    resolve("ok");
                    //console.log(this.mandantes);
                    //this.loading = false;
                });
            });
        },
        async getMandantesHijos() {
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        token: this.token,
                    },
                };
                console.log('mandante: ',this.datosFormulario.mandante)
                axios.get("/mandantes/mandanteshijo/2/"+ this.datosFormulario.mandante, config).then((response) => {
                    this.mandantesObras = response.data;
                    
                    this.mandantesObras.push({_id: 909090, nombre: 'Nueva obra',contactos: []});
                    //const contactosObras = 
                    this.mandantesObras.map((v,i,arr) => this.mandantesObras[i].contactos = ((v.hasOwnProperty('contactos')) ? [...v.contactos, {_id: 909090, nombre: 'Nuevo contacto obra'}] : [{_id: 909090, nombre: 'Nuevo contacto obra'}]));
                    console.log('aca cagada');
                    console.log(this.mandantesObras);
                    console.log(this.datosFormulario.obra);
                    //console.log(this.mandantesObras.filter((c) => c._id === this.datosFormulario.obra))
                    //this.mandantesObras.contactos.push({_id: 909090, nombre: 'Nuevo contacto obra'});
                    //console.log(this.mandantesObras)
                    resolve("ok");
                    //console.log(this.mandantes);
                    //this.loading = false;
                });
            });
        },
        async changeSelectMandantes(llega) {
            console.log("Cambio a: " + this.datosFormulario.mandante);
            this.datosFormulario.obras = false;
            this.datosFormulario.obra = null;
            this.datosFormulario.contacto_obra = null;
            await this.getMandantesHijos();


            //let result = this.mandantes.filter((c) => c._id === this.datosFormulario.mandante);
            //console.log(result[0].contactos);
        },
        async changeSelectMandantesObra() {
            console.log("Cambio a: " + this.datosFormulario.obra);
            if(this.datosFormulario.obra === 909090){
              this.dialogObras = true;
            }
        },
        async changeSelectContactoObra() {
            console.log("Cambio a: " + this.datosFormulario.contacto_obra);
            if(this.datosFormulario.contacto_obra === 909090){
              this.dialogObras = true;
            }
        },
        async cerrarObras(a){
          await this.getMandantesHijos();
          //console.log(a);
          this.datosFormulario.obra = '';
          this.dialogObras = false;
        },
        async getData() {
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        token: this.token,
                    },
                };
                if (this.idDato) {
                    axios
                        .get("/presupuestos/presupuesto/" + this.idDato, config)
                        .then((response) => {
                        console.log(response);
                        this.datosFormulario = new datosReporte(response.data);
                        this.datosFormulario.mandante = response.data.mandante._id;
                        this.status_bd = response.data.status;
                        this.datosFormulario.fecha_envio = moment(this.datosFormulario.fecha_envio, "YYYY-MM-DD").format("YYYY-MM-DD");
                        if (response.data.fecha_adjudicacion) {
                            this.datosFormulario.fecha_adjudicacion = moment(this.datosFormulario.fecha_adjudicacion, "YYYY-MM-DD").format("YYYY-MM-DD");
                        }
                        resolve("ok");
                    });
                }
                else {
                }
            });
        },
        getLastNumber() {
            let config = {
                headers: {
                    token: this.token,
                },
            };
            axios
                .get("/presupuestos/ultimoNumeroPresupuesto/" + this.datosFormulario.tipo, config)
                .then((response) => {
                this.datosFormulario.nro_presupuesto = response.data.nro_presupuesto;
                console.log(response);
            });
        },
        async editData() {
            console.log("llego aca");
            this.loading = true;
            if (this.$refs.formulario.validate()) {
                let config = {
                    headers: {
                        token: this.token,
                    },
                };
                const formData = new FormData();
                formData.append("file", this.files_presupuesto);
                formData.append("tipo", this.datosFormulario.tipo);
                formData.append("nombreProyecto", this.datosFormulario.nombreProyecto);
                formData.append("mandante", this.datosFormulario.mandante);
                formData.append("contacto_mandante", this.datosFormulario.contacto_mandante);
                formData.append("nro_presupuesto", this.datosFormulario.nro_presupuesto);
                formData.append("status", this.datosFormulario.status);
                formData.append("fecha_envio", this.datosFormulario.fecha_envio);
                formData.append("fecha_adjudicacion", this.datosFormulario.fecha_adjudicacion);
                formData.append("valorProyectos", this.datosFormulario.valorProyectos);
                formData.append("valorIto", this.datosFormulario.valorIto);
                formData.append("valor", this.datosFormulario.valor);
                formData.append("tipo_moneda", this.datosFormulario.tipo_moneda);
                formData.append("observacion", this.datosFormulario.observacion);
                formData.append("proyectos", this.datosFormulario.proyectos);
                formData.append("archivos", this.datosFormulario.archivos);
                formData.append("archivo_presupuesto", this.datosFormulario.archivo_presupuesto);

                formData.append("obras", this.datosFormulario.obras);
                if(this.datosFormulario.obras){
                  formData.append("obra", this.datosFormulario.obra);
                  formData.append("contacto_obra", this.datosFormulario.contacto_obra);
                }
                await axios
                    .put("/presupuestos/presupuesto/" + this.idDato, formData, config)
                    .then((response) => {
                    /*if (this.status_bd == "1" && this.datosFormularios.status == "2") {
                      this.$router.push({
                        name: "presupuesto",
                        params: {
                          id: this.idDato,
                        },
                      });
                      this.getData();
                      this.loading = false;
                    } else {*/
                    this.$router.push({
                        name: "presupuestos",
                    });
                    //}
                    this.$toast.success("Grabado con exito!", "", this.notificationSystem.options.error);
                })
                    .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
                });
            }
            else {
                this.loading = false;
                this.$toast.error("Los campos en rojo son obligatorios, rellene y vuelva a intentarlo", "Error", this.notificationSystem.options.error);
            }
        },
       
        async saveData() {
            console.log("llego aca");
            this.loading = true;
            if (this.$refs.formulario.validate()) {
                let config = {
                    headers: {
                        token: this.token,
                    },
                };
                //console.log(this.datosFormulario);
                const formData = new FormData();
                formData.append("file", this.files_presupuesto);
                formData.append("tipo", this.datosFormulario.tipo);
                formData.append("nombreProyecto", this.datosFormulario.nombreProyecto);
                formData.append("mandante", this.datosFormulario.mandante);
                formData.append("contacto_mandante", this.datosFormulario.contacto_mandante);
                formData.append("nro_presupuesto", this.datosFormulario.nro_presupuesto);
                formData.append("status", this.datosFormulario.status);
                formData.append("fecha_envio", this.datosFormulario.fecha_envio);
                formData.append("fecha_adjudicacion", this.datosFormulario.fecha_adjudicacion);
                formData.append("valorProyectos", this.datosFormulario.valorProyectos);
                formData.append("valorIto", this.datosFormulario.valorIto);
                formData.append("valor", this.datosFormulario.valor);
                formData.append("tipo_moneda", this.datosFormulario.tipo_moneda);
                formData.append("observacion", this.datosFormulario.observacion);
                formData.append("proyectos", this.datosFormulario.proyectos);
                formData.append("archivos", this.datosFormulario.archivos);
                formData.append("archivo_presupuesto", this.datosFormulario.archivo_presupuesto);
                formData.append("obras", this.datosFormulario.obras);
                if(this.datosFormulario.obras){
                  formData.append("obra", this.datosFormulario.obra);
                  formData.append("contacto_obra", this.datosFormulario.contacto_obra);
                }
                await axios
                    .post("/presupuestos/presupuesto/", formData, config)
                    .then(async (response) => {
                    setTimeout(() => (this.loading = false), 3000);
                    if (this.status_bd == "0" && this.datosFormulario.status == "2") {
                        this.$router.push({
                            name: "presupuesto",
                            params: {
                                id: response.data._id,
                            },
                        });
                        this.idDato = response.data._id;
                        this.getData();
                    }
                    else {
                        this.$router.push({
                            name: "presupuestos",
                        });
                    }
                    this.$toast.success("Grabado con exito!", "", this.notificationSystem.options.error);
                })
                    .catch((e) => {
                    this.loading = false;
                    this.$toast.error("Ocurrio un error de sistema, vuelva a intentarlo", "Error", this.notificationSystem.options.error);
                });
            }
            else {
                this.loading = false;
                this.$toast.error("Los campos en rojo son obligatorios, rellene y vuelva a intentarlo", "Error", this.notificationSystem.options.error);
            }
        },
        getEstados(estado) {
            if (estado == true)
                return "green";
            else if (estado == false)
                return "red";
            else
                return "grey";
        },
        getEstadosTexto(estado) {
            if (estado == true)
                return "Activo";
            else if (estado == false)
                return "Inactivo";
            else
                return "N/A";
        },
    },
    async created() {
        if (this.$route.params.id) {
            this.idDato = this.$route.params.id;
        }
        else {
            //this.getLastNumber();
            //this.datosFormulario.nro_presupuesto = "PROY";
        }
        await this.getMandantes();
        await this.getData();
    },
    mounted() { },
    computed: {
        ...mapState(["token", "usuarioDB", "itemsMenu", "notificationSystem"]),
        formTitle() {
            return this.editedIndex === -1 ? "Nuevo Contacto" : "Editar Contacto";
        },
    },
    watch: {
        seleccionados: function (val) {
            this.datosFormulario.mods = [];
            for (let nodos of val) {
                this.datosFormulario.mods.push(nodos.id);
            }
        },
        datosFormulario: async function(val){
          console.log('cambio');
          console.log(val);
          await this.getMandantesHijos();
        }
    },
    filters: {
        filtro_valor: function (valor, tipo_moneda) {
            if (tipo_moneda == "1") {
                return `U.F ${valor}`;
            }
            else {
                return `$ ${valor}`;
            }
        },
        fecha_normal: function (valor) {
            return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        fecha_normal_con_hora: function (valor) {
            return moment(valor, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
        },
    },
    components: { FormularioObras, FormularioObrasModal }
};
</script>
<style>

th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
